@import "src/style.scss";

.navbar-desktop {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 0.5rem;
  background: $primary-color;
  z-index: 100;
  .menu {
    display: none;
  }
  @include center(row);
  .list-desktop {
    @include center(row);
    li {
      margin: 0 2rem;
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 0;
    width: 20px;
    right: 0 !important;
    .list-desktop {
      display: none;
    }
    .menu {
      width: 18px;
      height: 18px;
      display: block;
    }
  }
}
.navbar-phone {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  @include center(column);
  display: none;
  ul {
    @include center(column);
  }
  li {
    font-size: 24px;
    text-align: center;
    margin: 0.5rem 0;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    margin: 4px;
  }
  @media only screen and (max-width: 500px) {
    display: flex;
  }
}
.footer {
  width: 100%;
  background: $secondary-color;
  padding: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4,
  p,
  li {
    color: $primary-color;
  }
  ul {
    display: flex;
  }
  li {
    margin: 0 8px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
}
.round-button {
  position: relative;
  cursor: pointer;
  svg,
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  @media only screen and (max-width: 500px) {
    width: 80px I !important;
    height: 80px !important;
    margin-top: 1rem;
    svg {
      width: 80px I !important;
      height: 80px !important;
    }
  }
}
.component-media {
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
  .description {
    width: 65%;
    position: relative;
    p {
      margin-top: 1rem;
    }
    .quote {
      position: absolute;
      top: -5%;
      left: -3%;
      width: 60px;
    }
  }
  .media-img {
    width: 30%;
    img{
      width: 100%;
      border: 1px solid $secondary-color;
    }
  }
  .by {
    font-weight: bolder;
  }
  .phone-img {
    display: none;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    .media-img {
      width: 100%;
      height: auto;
    }
    .description {
      width: 100%;
      .quote {
        position: absolute;
        width: 40px;
        top: -15%;
        left: -5%;
      }
    }
    .phone-img {
      display: block;
    }
    .desktop-img {
      display: none;
    }
  }
}
.button {
  padding: 0.5rem 2rem;
  background: $primary-color;
  color: $secondary-color;
  border-radius: 8px;
  border: 1px solid $secondary-color;
  width: auto;

  font-family: "Raleway-Regular", sans-serif;
  color: $secondary-color;
  font-weight: bolder;
  list-style-type: none;
  font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1400 - 320)));
  cursor: pointer;
}
.button-container {
  display: flex;
  margin-top: 2rem;
  .button:first-of-type {
    margin-right: 1rem;
  }
}
