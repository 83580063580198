@import "src/style.scss";

.home {
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .navbar-desc {
    padding: 8% 8% 4% 8%;
    width: 100%;
    height: 25vh;
    @include center(row);
    justify-content: space-between;
    .scale-appear {
      width: 40%;
    }
    p {
      width: 100%;
    }
  }
  .banner {
    max-height: 75vh;
    // width: 100%;
    // object-fit: cover;
    // object-position: 90% 80%;
    filter: grayscale(100%);
  }
  .space-between {
    margin-top: 1rem;
    h2 {
      margin-right: 2rem;
    }
    p {
      width: 100%;
    }
  }
  .portfolio {
    @include center(row);
    justify-content: space-between;
    width: 100%;
    padding: 1rem 8%;
    border-radius: 24px;
    border-top: 1px solid $secondary-color;
    img {
      width: 40px;
      height: 40px;
    }
    span {
      display: flex;
      p {
        margin-right: 12px;
      }
    }
  }
  .sec-portfolio {
    padding-bottom: 16px;
  }

  .selected-work {
    margin-top: 16px;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    img {
      border: 1px solid $secondary-color;
      width: 20rem;
      height: auto;
    }
  }

  @media only screen and (max-width: 500px) {
    .navbar-desc {
      flex-direction: column;
      padding: 4px;
      justify-content: center;
      .scale-appear {
        width: 90%;
        text-align: center;
      }
    }
    .space-between {
      flex-direction: column;
      h2 {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
.graphics {
  h1 {
    margin-bottom: 2rem;
  }
  .shared-content {
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    img {
      width: 20rem;
      border: 1px solid $secondary-color;
    }
  }
}
.research {
  h1 {
    margin-bottom: 2rem;
  }
  .media-img{
    width: 25vw;
    height: 25vw;
    object-fit: cover !important;
    border: 1px solid $secondary-color;
    @media only screen and (max-width: 500px) {
      width: 100%;
      height: auto;
    }
  }
}